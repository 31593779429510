import constants from 'styles/constants.scss?inline'
import { parseCssImport } from './services/css-import'

export const US_PHONE_NUMBER = '+1 212 986 9300'
export const UK_PHONE_NUMBER = '+44 (0) 207 847 1979'
export const SG_PHONE_NUMBER = '+65 6859 0160'

export const EMAIL_ADDRESS = 'data@haver.com'

export const HOMEPAGE_URL = 'https://www.haver.com'
export const UPDATES_URL = 'https://www.haver.com/data-updates'
export const DISCLAIMER_URL = 'https://www.haver.com/disclaimer'
export const PRIVACY_URL = 'https://www.haver.com/disclaimer'
export const FEEDBACK_URL = 'https://www.haver.com/contact-us'
export const RELEASE_CALENDARS_URL = 'https://www.haver.com/client/release-calendars'
export const DATA_NEWS_URL = 'https://www.haver.com/client/data-news'

export const MAX_VARIABLES = 8

export const SERIES_TRANSFORMATIONS_LIMIT = 25

export const DUMMY_LABEL = '__'

export const SERIES_CONTAINER_ID = 'series-container'
export const CHART_GROUP_ID = 'chart-group'

export const MARGINS = { top: 96, right: 110, bottom: 72, left: 110 }
export const MOBILE_MARGINS = { top: 46, right: 70, bottom: 72, left: 70 }

export const BASE_FONT = 'DM Sans, sans-serif'
export const MONOSPACE_FONT = 'DM Mono, monospace'

export const CSS_CONSTANTS = parseCssImport(constants)
