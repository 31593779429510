// eslint-disable max-lines

import * as React from 'react'

import { bindBem } from '../../bem'

import { Footnote } from './Footnote'
import { DUMMY_LABEL, MAX_VARIABLES } from '../../constants'
import { Button } from '../Button'

import siderbarIcon from '../../static/sidebar-chart-bar.svg'
import seriesLoader from '../../static/graph-loader.gif'

import { ReactComponent as Database } from 'static/database.svg'
import { ReactComponent as Folder } from 'static/folder.svg'

import { ReactComponent as Star } from 'static/star.svg'
import { ReactComponent as EmptyStar } from 'static/empty-star.svg'

import './DataDirectoryEntry.scss'

export interface IProps {
  entry: IDirectoryEntry
  level?: number
  onClick: (entry: IDirectoryEntry) => void
  onSecondaryClick?: React.EventHandler<React.MouseEvent>
  onFootnoteClick?: () => void
  isPreview?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  isLoading?: boolean
  prefix?: boolean
  variablesCount?: number
  isMobile?: boolean
}

export const DataDirectoryEntry = (props: IProps) => {
  switch (props.entry.type) {
    case 'db':
      return <DBEntry {...props} />
    case 'label':
      return <LabelEntry {...props} />
    case 'separator':
      return <SeparatorEntry {...props} />
    case 'folder':
      return <GroupEntry {...props} />
    case 'archive':
      return <ArchiveEntry {...props} />
    case 'category':
      return <CategoryEntry {...props} />
    case 'page':
      return <PageEntry {...props} />
    case 'series':
      return <SeriesEntry {...props} isDisabled={props.isDisabled || props.isPreview} />
  }
  return null
}

const { block, element } = bindBem('DataDirectoryEntry')
const PADDING = 12

export const DBEntry = ({
  entry,
  onClick,
  onSecondaryClick,
  isSelected,
  isMobile,
  level = 0,
}: IProps) => {
  const style = { paddingLeft: PADDING * level }

  return (
    <div
      className={block({
        isPreview: entry.isPreview,
        isDisabled: !entry.isEnabled,
        isSelected,
        db: true,
        isMobile,
      })}
      onClick={() => onClick(entry)}
      style={style}
    >
      <div className={element('Icon')}>
        <Database />
      </div>
      {!isMobile && (
        <>
          <div className={element('HoverIcon')} onClick={onSecondaryClick}>
            <EmptyStar />
          </div>
          <div
            className={element('StarredIcon')}
            onClick={onSecondaryClick}
            data-cy="starred-icon"
          >
            <Star />
          </div>
        </>
      )}
      <div className={element('Label')}>
        {entry.description} ({entry.id.toUpperCase()})
        {!entry.isEnabled && (
          <div className={element('Unavailable')}>Currently Unavailable</div>
        )}
      </div>
    </div>
  )
}

export const PageEntry = ({ entry, onClick, onFootnoteClick, level = 0 }: IProps) => {
  const { description, footnoteId } = entry
  const style = { paddingLeft: PADDING * level }
  return (
    <div className={block()} onClick={() => onClick(entry)} style={style}>
      <div className={element('Icon')}>
        <Folder />
      </div>
      <div className={element('Label')}>
        {description}
        <Footnote footnoteId={footnoteId} onClick={onFootnoteClick} />
      </div>
    </div>
  )
}

export const LabelEntry = ({ entry, onFootnoteClick, level = 0 }: IProps) => {
  const isDummy = entry.description.startsWith(DUMMY_LABEL)
  const description = isDummy ? '' : entry.description
  const style = { paddingLeft: PADDING * level, minHeight: isDummy ? 0 : 28 }
  return (
    <div className={block({ label: true, separator: isDummy })} style={style}>
      <div className={element('Label')}>
        {description}
        <Footnote footnoteId={entry.footnoteId} onClick={onFootnoteClick} />
      </div>
    </div>
  )
}

export const GroupEntry = ({ entry, level = 0 }: IProps) => {
  const style = { paddingLeft: PADDING * level, minHeight: 28 }
  return (
    <div className={block({ label: true })} style={style}>
      <div className={element('Label')}>{entry.description}</div>
    </div>
  )
}

export const SeparatorEntry = ({ level = 0 }: IProps) => {
  const style = { paddingLeft: PADDING * level, minHeight: 0 }
  return (
    <div className={block({ label: true, separator: true })} style={style}>
      <div className={element('Label')} />
    </div>
  )
}

export const ArchiveEntry = ({ entry }: IProps) => {
  const isDummy = entry.description.startsWith(DUMMY_LABEL)
  const description = isDummy ? '' : entry.description
  const style = { minHeight: isDummy ? 0 : 28 }
  return (
    <div className={block({ archive: true, separator: isDummy })} style={style}>
      <div className={element('Label')}>
        {description}
        <Footnote footnoteId={entry.footnoteId} />
      </div>
    </div>
  )
}

export const CategoryEntry = ({ entry }: IProps) => {
  const style = { minHeight: 28 }
  return (
    <div className={block({ category: true })} style={style}>
      <div className={element('Label')}>{entry.description}</div>
    </div>
  )
}

export const SeriesEntry = ({
  entry,
  onClick,
  onSecondaryClick,
  onFootnoteClick,
  level = 0,
  isDisabled = false,
  isSelected = false,
  isLoading = false,
  prefix = false,
  variablesCount = 0,
  isMobile = false,
}: IProps) => {
  const style = { paddingLeft: PADDING * level }
  return (
    <div
      className={block({ isSelected, isDisabled, isLoading, series: true })}
      onClick={() => !isDisabled && onClick(entry)}
      style={style}
    >
      {prefix && <div className={element('Prefix')} />}
      <div className={element('Label', { withFootnote: !!entry.footnoteId })}>
        {entry.description}
        <Footnote footnoteId={entry.footnoteId} onClick={onFootnoteClick} />
      </div>
      <img
        className={element('RightIcon')}
        src={isLoading ? seriesLoader : siderbarIcon}
      />
      {!isMobile &&
        onSecondaryClick &&
        variablesCount < MAX_VARIABLES &&
        variablesCount > 0 && (
          <div className={element('AddButtonMask')}>
            <Button
              className={element('AddSeries')}
              text="Add"
              style="dark"
              onClick={onSecondaryClick}
              size="small"
            />
          </div>
        )}
    </div>
  )
}
